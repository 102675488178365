import { minLength, minValue, integer, required, requiredIf, maxLength } from "vuelidate/lib/validators"
import { range, every } from "lodash-es"
import { DAY_FEE_TYPE, FIXED_FEE_TYPE, PERIOD_FEE_TYPE, EXPLANATION_CAPTION_MAX_LENGTH } from "./constants"
import { compactValues } from "../TimeDivision/helpers"

const hoursMustBeInAscOrder = self => {
  const hours = compactValues(self.hours_before, "hours")
  return every(range(1, hours.length), index => hours[index - 1] < hours[index])
}

const valueValidator = ({ min }) => ({
  required,
  minValue: minValue(min),
  integer
})

export const buildFeeValidator = feeType => {
  switch (feeType) {
    case FIXED_FEE_TYPE:
      return {
        fee_data: {
          price: valueValidator({ min: 0 })
        }
      }
    case PERIOD_FEE_TYPE:
      return {
        fee_data: {
          hours_before: {
            $each: {
              hours: valueValidator({ min: 1 }),
              price: valueValidator({ min: 1 })
            }
          },
          hoursMustBeInAscOrder
        }
      }
    case DAY_FEE_TYPE:
      return {
        fee_data: {
          price: valueValidator({ min: 1 }),
          days_upper_limit: {
            required: requiredIf(function(nested) {
              return nested.use_limit
            }),
            greaterThanCheck: (value, self) => {
              return !self.use_limit || value >= self.price
            },
            minValue: minValue(1),
            integer
          },
          use_limit: { required }
        }
      }
  }
}

export const optionValidator = feeType => ({
  name: {
    required,
    minLength: minLength(1)
  },
  description: {
    maxLength: maxLength(EXPLANATION_CAPTION_MAX_LENGTH)
  },
  fee_type: { required },
  ...buildFeeValidator(feeType)
})
